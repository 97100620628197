import * as React from 'react'
import { useEffect } from 'react'
import * as classes from './Form.module.css'

const FormComp = () => {
  useEffect(() => {
    // HubSpotフォームスクリプトが既にロードされているか確認
    const script = document.querySelector('script[src*="//js.hsforms.net"]');
    if (!script) {
      // HubSpotフォームスクリプトをロード
      const hubspotScript = document.createElement('script');
      hubspotScript.src = '//js.hsforms.net/forms/embed/v2.js';
      hubspotScript.async = true;
      hubspotScript.defer = true;
      document.body.appendChild(hubspotScript);

      hubspotScript.addEventListener('load', () => {
        createForm();
      });
    } else {
      // スクリプトが既にロードされている場合
      if (window.hbspt) {
        createForm();
      } else {
        // hbsptオブジェクトがまだ利用できない場合、少し待ってから再試行
        setTimeout(() => {
          if (window.hbspt) {
            createForm();
          }
        }, 1000);
      }
    }

    // フォーム作成関数
    function createForm() {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1", // HubSpotのリージョン
          portalId: process.env.GATSBY_HUBSPOT_PORTAL_ID,
          formId: process.env.GATSBY_HUBSPOT_FORM_GUID,
          target: "#hubspot-form-container",
          onFormSubmit: function ($form) {
            console.log("Form submitted successfully");
          }
        });
      }
    }

    // コンポーネントのアンマウント時にクリーンアップ
    return () => {};
  }, []);

  return (
    <section className={classes.formSection} id={'contact'}>
      <h2 className={classes.title}>
        Looking for ways to enhance your cloud infrastructure? <br />
        Let us know how we can help
      </h2>

      {/* HubSpotフォームが挿入されるコンテナ */}
      <div id="hubspot-form-container" className={classes.formWrapper}></div>
      
    </section>
  );
};

export default FormComp;
