import * as React from 'react'
import * as classes from './program.module.css'
import { StaticImage } from 'gatsby-plugin-image'

type ItemsType = {
  published: string
  thumbnail: string
  title: string
  url: string
}
type SocialContributionCompPropsType = {
  rssPosts: { node: ItemsType }[]
}

const SocialContributionComp = ({
  rssPosts,
}: SocialContributionCompPropsType) => {
  return (
    <section className={classes.typography}>
      <div className={classes.titleBlock}>
        <StaticImage
          src="../../images/logo_black_2023.png"
          alt={`SocialContributionImg`}
          className={classes.catchImg}
          width={315}
        />
        <h2 className={classes.title}>
          We are proud to support the following individuals and organizations
          through our 
          <a
            className={classes.link}
            href="https://www.linkedin.com/feed/update/urn:li:activity:6641627923839238147"
            target="_blank"
          >
            Social Contributions Program
          </a>
        </h2>
      </div>
      <div className={classes.container}>
        {rssPosts &&
          rssPosts.map(({ node: post }) => {
            console.log('post', JSON.stringify(post))
            return (
              <div className={classes.item} key={post.title}>
                <div>
                  {/* 2025現在、RSSでサムネイルをサポートしていない
                  <img
                    className={classes.ContributionImg}
                    src={post.url}
                    alt={post.title}
                  />*/}
                </div>
                <p className={classes.paragraph}>{post.title}</p>
                <a
                  href={post.url}
                  target="_blank"
                  className={`${classes.paragraph} ${classes.btn}`}
                >
                  Read More
                </a>
              </div>
            )
          })}
      </div>
    </section>
  )
}
export default SocialContributionComp
