// extracted by mini-css-extract-plugin
export var btn = "Form-module--btn--8DwI4";
export var checkboxSpan = "Form-module--checkboxSpan--I6t+U";
export var checkboxWrapper = "Form-module--checkboxWrapper--Zy1Wu";
export var formFields = "Form-module--formFields--S--3v";
export var formSection = "Form-module--formSection--4Nc+R";
export var formWrapper = "Form-module--formWrapper--ZfQnE";
export var paragraph = "Form-module--paragraph--O7sCF";
export var successMessage = "Form-module--successMessage---d8yS";
export var swiperSlide = "Form-module--swiper-slide--gfuoV";
export var title = "Form-module--title--OuDCl";